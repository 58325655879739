// https://firebase.google.com/docs/web/setup#add-sdks-initialize
import "firebase/analytics";

import firebase from "firebase/app";

import { isLocalStage } from "./config";

function initialize() {
  if (isLocalStage) {
    return;
  }

  const firebaseConfig = {
    apiKey: "AIzaSyDw1CWlpw-5RwApY-dhai0ddECKKKqhmH4",
    authDomain: "tool-cv-converter.firebaseapp.com",
    projectId: "tool-cv-converter",
    storageBucket: "tool-cv-converter.appspot.com",
    messagingSenderId: "924683299950",
    appId: "1:924683299950:web:b56407f86384a7f73b8a7f",
    measurementId: "G-Y7N5HJYWS3",
  };

  firebase.initializeApp(firebaseConfig);

  // https://firebase.google.com/docs/analytics/get-started?platform=web#add-sdk
  firebase.analytics();
}

initialize();
