import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";

/* eslint-disable @typescript-eslint/no-explicit-any*/
function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"© OPT Inc, All Rights Reserved."}
    </Typography>
  );
}

const tiers = [
  {
    title: "データ取得の自動化",
    description: [
      "広告効果計測ツール（Google Analytics等）のデータを自動取得し、媒体実績データと紐付けを行えます",
    ],
  },
  {
    title: "取得データのフィルタリング",
    description: [
      "データ取得を実施する際にフィルタリングを行い、必要なデータのみの抽出を行えます",
    ],
  },
  {
    title: "分析・レポーティング",
    description: [
      "媒体の実績と各種広告効果計測ツールのデータを合わせて分析やレポーティングする事で運用業務を効率的に行えます",
    ],
  },
];

const images = [
  {
    image: "image01.png",
    title: "各指標のサマリーレポート",
    description: "各指標のサマリーレポート",
  },
  {
    image: "image02.png",
    title: "カテゴリ別の集計",
    description: "カテゴリ別の集計",
  },
  {
    image: "image03.png",
    title: "クリエイティブ一覧のレポート",
    description: "クリエイティブ一覧のレポート",
  },
];

const footers = [
  {
    title: "Company",
    description: [
      { name: "会社概要", link: "https://www.opt.ne.jp/company/" },
      { name: "お問い合わせ", link: "https://www.opt.ne.jp/contact/" },
      { name: "アクセス", link: "https://www.opt.ne.jp/company/access/" },
    ],
  },
  {
    title: "Service",
    description: [{ name: "サービス", link: "https://www.opt.ne.jp/service/" }],
  },
  {
    title: "Teams",
    description: [
      { name: "OptTechnologies", link: "https://opt-technologies.jp/" },
    ],
  },

  {
    title: "Legal",
    description: [{ name: "プライバシーポリシー", link: "/privacy/" }],
  },
];

function LandingPage() {
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: "wrap" }}>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            CVアップ自動化ツール
          </Typography>
        </Toolbar>
      </AppBar>

      <Container
        disableGutters
        maxWidth="xl"
        component="main"
        sx={{ pt: 8, pb: 6 }}
      >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          CVアップ自動化ツールとは
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
          component="p"
        >
          各種広告効果計測ツールのデータの取得から媒体実績データとの紐付けまでをサポートするツールです
        </Typography>
      </Container>

      <Container
        disableGutters
        maxWidth="xl"
        component="main"
        sx={{ pt: 8, pb: 6 }}
      >
        <Typography
          component="h1"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
        >
          以下の機能で利用者の課題を解決します
        </Typography>
      </Container>

      <Container maxWidth="xl" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.title} xs={12} sm={6} md={4}>
              <Card>
                <CardHeader
                  title={tier.title}
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{
                    align: "center",
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light"
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                      mb: 2,
                    }}
                  >
                    <Typography
                      component="h2"
                      variant="h3"
                      color="text.primary"
                    ></Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Container
        disableGutters
        maxWidth="xl"
        component="main"
        sx={{ pt: 8, pb: 6 }}
      >
        <Typography
          component="h1"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
        >
          分析・レポーティング
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
          component="p"
        >
          取得した広告効果計測ツールのデータを社内・クライアント向けにWeb上で分析・レポーティングできるようにします
        </Typography>
      </Container>

      <Container maxWidth="xl">
        <Grid container spacing={4}>
          {images.map((img) => (
            <Grid item xs={12} sm={6} md={4}>
              <Card sx={{ display: "flex", flexDirection: "column" }}>
                <CardMedia
                  component="img"
                  alt={img.title}
                  image={img.image}
                  title={img.title}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography>{img.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Footer */}
      <Container
        maxWidth="md"
        component="footer"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 8,
          py: [3, 6],
        }}
      >
        <Grid container spacing={4} justifyContent="space-evenly">
          {footers.map((footer) => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                {footer.title}
              </Typography>
              <ul>
                {footer.description.map((item) => (
                  <li key={item.name}>
                    <Link
                      href={item.link}
                      variant="subtitle1"
                      color="text.secondary"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
        <Copyright sx={{ mt: 5 }} />
      </Container>
      {/* End footer */}
    </React.Fragment>
  );
}

export function App() {
  return <LandingPage />;
}
